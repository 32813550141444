import { withProfiler } from '@sentry/react'
import { Suspense, useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { Userpilot } from 'userpilot'

import { NoEmailLoginRedirect } from '@/company/components/NoEmailLoginRedirect/NoEmailLoginRedirect'
import { NoEmailRegisterRedirect } from '@/company/components/NoEmailRegisterRedirect/NoEmailRegisterRedirect'
import { Consents } from '@/consents/components/Consents'
import { PageNotFound } from '@/core/components/PageNotFound/PageNotFound'
import Loader from '@/core/components/ui/Loader/Loader'
import {
  URL_ACHIEVEMENTS,
  URL_CERTIFICATE,
  URL_CERTIFICATES,
  URL_COMPANY,
  URL_CONSENTS,
  URL_CORE_SKILLS_ASSESSMENT,
  URL_CORE_SKILLS_ASSESSMENT_DEPRECATED,
  URL_COURSE,
  URL_COURSE_GENERIC,
  URL_DASHBOARD,
  URL_DISCOVER,
  URL_GLOSSARY,
  URL_GROWTH_MAP,
  URL_LEARNGROUP,
  URL_LEARNPATHS,
  URL_LEARNPATHS_AS_COURSES,
  URL_LECTURES,
  URL_LOGIN,
  URL_NO_EMAIL_LOGIN,
  URL_NO_EMAIL_REGISTER,
  URL_POLL_PAGE,
  URL_ROOT,
  URL_SIGNUP,
  URL_SKILL,
  URL_TOPIC,
  URL_USER,
  URL_USER_PROGRESS,
} from '@/core/constants/constants'
import { lazyLoad } from '@/core/utils/lazyLoad'
import { KeycloakLogin } from '@/user/components/KeycloakLogin/KeycloakLogin'

import { AppManagerFetch } from './AppManagerFetch'
import { AppManagerIdentify } from './AppManagerIdentify'
import { AppManagerRedirect } from './AppManagerRedirect'
import { AppManagerSSO } from './AppManagerSSO'
import { AppManagerScorm } from './AppManagerScorm'
import { AppManagerTitle } from './AppManagerTitle'

const StudentApp = lazyLoad(async () => ({
  default: (await import('./student/StudentApp')).ProfiledStudentApp,
}))
const ManagerApp = lazyLoad(async () => ({
  default: (await import('./manager/ManagerApp')).ProfiledManagerApp,
}))
const Signup = lazyLoad(() => import('@/signup/components/Signup'))
const CoreSkillsAssessmentRouter = lazyLoad(() =>
  import(
    '@/core-skills-assessment/components/CoreSkillsAssessmentRouter/CoreSkillsAssessmentRouter'
  ).then((module) => ({ default: module.CoreSkillsAssessmentRouter })),
)

function LocationListener() {
  const location = useLocation()

  useEffect(() => {
    Userpilot.reload()
  }, [location.pathname])

  return null
}

function AppRouterWithoutProfiler() {
  return (
    <>
      <LocationListener />
      <Switch>
        <Route exact path="/crash">
          {() => {
            // trigger a on-demand crash to test and see fallback
            throw new Error('Test Crash!')
          }}
        </Route>
        <Route path={URL_NO_EMAIL_LOGIN}>
          <NoEmailLoginRedirect />
        </Route>
        <Route path={URL_NO_EMAIL_REGISTER}>
          <NoEmailRegisterRedirect />
        </Route>
        <Route>
          <AppManagerSSO>
            <AppManagerFetch>
              <AppManagerTitle>
                <AppManagerScorm>
                  <AppManagerIdentify>
                    <AppManagerRedirect>
                      <Suspense fallback={<Loader fixed />}>
                        <Switch>
                          <Redirect exact from={URL_ROOT} to={URL_DASHBOARD} />
                          <Route path={URL_LOGIN}>
                            <KeycloakLogin />
                          </Route>
                          <Route path={URL_CONSENTS}>
                            <Consents />
                          </Route>
                          <Route
                            path={[
                              URL_ACHIEVEMENTS,
                              URL_CERTIFICATE,
                              URL_CERTIFICATES,
                              URL_DASHBOARD,
                              URL_DISCOVER,
                              URL_GLOSSARY,
                              URL_COURSE,
                              URL_COURSE_GENERIC,
                              URL_GROWTH_MAP,
                              URL_LECTURES,
                              URL_LEARNGROUP,
                              URL_LEARNPATHS,
                              URL_LEARNPATHS_AS_COURSES,
                              URL_SKILL,
                              URL_USER,
                              URL_USER_PROGRESS,
                              URL_TOPIC,
                              URL_POLL_PAGE,
                            ]}>
                            <StudentApp />
                          </Route>
                          <Route path={URL_COMPANY}>
                            <ManagerApp />
                          </Route>
                          <Route path={URL_SIGNUP}>
                            <Signup />
                          </Route>
                          <Route
                            path={[
                              URL_CORE_SKILLS_ASSESSMENT,
                              URL_CORE_SKILLS_ASSESSMENT_DEPRECATED,
                            ]}>
                            <CoreSkillsAssessmentRouter />
                          </Route>
                          <Route path="*">
                            <PageNotFound />
                          </Route>
                        </Switch>
                      </Suspense>
                    </AppManagerRedirect>
                  </AppManagerIdentify>
                </AppManagerScorm>
              </AppManagerTitle>
            </AppManagerFetch>
          </AppManagerSSO>
        </Route>
      </Switch>
    </>
  )
}

export const AppRouter = withProfiler(AppRouterWithoutProfiler)
