import {
  compact,
  every,
  flatten,
  get,
  getOr,
  has,
  identity,
  includes,
  intersection,
  isEmpty,
  lt,
  map,
  merge,
  omit,
  overSome,
  pipe,
  size,
  some,
  split,
  startsWith,
  trim,
} from 'lodash/fp'
import { createSelector } from 'reselect'

import {
  URL_CERTIFICATE,
  URL_CERTIFICATES,
  URL_COMPANY,
  URL_CORE_SKILLS_ASSESSMENT,
  URL_COURSE,
  URL_DASHBOARD,
  URL_DASHBOARD_COURSE,
  URL_DASHBOARD_OLD,
  URL_DISCOVER,
  URL_GLOSSARY,
  URL_GROWTH_MAP,
  URL_LEARNGROUP,
  URL_LEARNPATHS,
  URL_LECTURES,
  URL_LOGIN,
  URL_PASSWORD_RESET,
  URL_SIGNUP_CREDENTIALS,
  URL_SIGNUP_POLL,
  URL_SIGNUP_PROFILE,
  URL_SIGNUP_WELCOME,
  URL_USER,
} from '@/core/constants/constants'
import keycloak from '@/core/keycloak'
import { pathnameSelector } from '@/core/selectors'
import isLocalStorageEnabled from '@/core/utils/localStorage/isLocalStorageEnabled'
import { env } from '@/env'

export const rootSelector = get('user')

export const accountSelector = createSelector(
  rootSelector,
  getOr({}, 'account'),
)

export const metaSelector = createSelector(
  rootSelector,
  omit(['data', 'account']),
)
export const fetchedSelector = createSelector(
  metaSelector,
  getOr(false, 'fetched'),
)

export const fetchingSelector = createSelector(
  metaSelector,
  getOr(false, 'fetching'),
)

export const loadingSelector = createSelector(
  metaSelector,
  ({ fetched, failed }) => !fetched && !failed,
)

export const userDataSelector = createSelector(rootSelector, get('data'))

export const dataSelector = createSelector(
  userDataSelector,
  accountSelector,
  (data, account) => {
    const {
      department,
      given_name: first_name,
      family_name: last_name,
      phone,
      title,
      date_of_birth,
      job_title,
    } = account

    const email =
      has('identity.email', data) && isEmpty(data.identity.email)
        ? data.identity.email
        : account.email

    const userIdentity = merge(data?.identity, {
      department,
      date_of_birth,
      email,
      first_name,
      job_title,
      last_name,
      phone,
      title,
    })

    return omit('identity', { ...data, ...userIdentity })
  },
)

export const errorSelector = createSelector(metaSelector, get('error'))

export const requiresFetchSelector = createSelector(
  metaSelector,
  ({ fetching, fetched, failed, fresh }) =>
    // @ts-ignore Not sure if it works, while moving to TS leaving as is.
    !some(null, [fetching, fetched && fresh, failed && fresh]),
)

export const emailSelector = createSelector(dataSelector, get('email'))

export const rolesSelector = createSelector(dataSelector, getOr([], 'roles'))

export const userLangSelector = createSelector(dataSelector, get('lang'))

export const configFeaturesSelector = () =>
  pipe([split(','), map(trim), compact])(env.REACT_APP_FEATURES)

export const devFeaturesSelector = () =>
  pipe([get('features'), split(','), map(trim), compact])(
    env.DEV && isLocalStorageEnabled() ? localStorage : '',
  )

export const featuresSelector = createSelector(
  dataSelector,
  devFeaturesSelector,
  configFeaturesSelector,
  (data, devFeatures, configFeatures) =>
    flatten([getOr([], 'features', data), devFeatures, configFeatures]),
)

export const createIsFeatureSelector = (feature) =>
  createSelector(featuresSelector, includes(feature))

export const idSelector = createSelector(dataSelector, get('id'))

export const anonymousPathnameSelector = createSelector(
  pathnameSelector,
  overSome(
    // @ts-ignore Not sure if it works, while moving to TS leaving as is.
    map(startsWith, [
      // List of pathnames which are not accessible by authenticated user
      URL_LOGIN,
      URL_SIGNUP_WELCOME,
      URL_SIGNUP_CREDENTIALS,
      URL_PASSWORD_RESET,
    ]),
  ),
)

export const protectedPathnameSelector = createSelector(
  pathnameSelector,
  overSome(
    // @ts-ignore Not sure if it works, while moving to TS leaving as is.
    map(startsWith, [
      // List of pathnames which are accessible only by authenticated user
      URL_SIGNUP_PROFILE,
      URL_SIGNUP_POLL,
      URL_CERTIFICATE,
      URL_CERTIFICATES,
      URL_COMPANY,
      URL_COURSE,
      URL_DASHBOARD,
      URL_DASHBOARD_OLD,
      URL_DASHBOARD_COURSE,
      URL_DISCOVER,
      URL_LECTURES,
      URL_LEARNPATHS,
      URL_LEARNGROUP,
      URL_GLOSSARY,
      URL_USER,
      URL_GROWTH_MAP,
      URL_CORE_SKILLS_ASSESSMENT,
    ]),
  ),
)

export const requiresLoginSelector = createSelector(
  [protectedPathnameSelector],
  (protectedPath) =>
    every(identity, [keycloak.authenticated !== true, protectedPath]),
)

export const isStudentSelector = createSelector(
  rolesSelector,
  includes('student'),
)

export const isManagerSelector = createSelector(
  rolesSelector,
  includes('manager'),
)

export const isManagingRoleSelector = createSelector(
  rolesSelector,
  pipe([
    intersection(['owner', 'admin', 'manager', 'company_administrator']),
    size,
    lt(0),
  ]),
)

export const isAdminSelector = createSelector(rolesSelector, includes('admin'))

export const isOwnerSelector = createSelector(rolesSelector, includes('owner'))

export const hasCurrentSkillSelector = createSelector(
  dataSelector,
  getOr(false, 'current_skill_set'),
)
